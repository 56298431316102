// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-certificacion-index-js": () => import("./../../../src/pages/certificacion/index.js" /* webpackChunkName: "component---src-pages-certificacion-index-js" */),
  "component---src-pages-comunidad-itcad-index-js": () => import("./../../../src/pages/comunidad_itcad/index.js" /* webpackChunkName: "component---src-pages-comunidad-itcad-index-js" */),
  "component---src-pages-contacto-index-js": () => import("./../../../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-cursos-[id]-js": () => import("./../../../src/pages/cursos/[id].js" /* webpackChunkName: "component---src-pages-cursos-[id]-js" */),
  "component---src-pages-evento-[id]-js": () => import("./../../../src/pages/evento/[id].js" /* webpackChunkName: "component---src-pages-evento-[id]-js" */),
  "component---src-pages-eventos-index-js": () => import("./../../../src/pages/eventos/index.js" /* webpackChunkName: "component---src-pages-eventos-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-index-js": () => import("./../../../src/pages/nosotros/index.js" /* webpackChunkName: "component---src-pages-nosotros-index-js" */),
  "component---src-pages-preguntas-frecuentes-index-js": () => import("./../../../src/pages/preguntas_frecuentes/index.js" /* webpackChunkName: "component---src-pages-preguntas-frecuentes-index-js" */),
  "component---src-pages-programacion-actividades-js": () => import("./../../../src/pages/programacion/actividades.js" /* webpackChunkName: "component---src-pages-programacion-actividades-js" */),
  "component---src-pages-programacion-cursos-disponibles-js": () => import("./../../../src/pages/programacion/cursos-disponibles.js" /* webpackChunkName: "component---src-pages-programacion-cursos-disponibles-js" */),
  "component---src-pages-servicios-index-js": () => import("./../../../src/pages/servicios/index.js" /* webpackChunkName: "component---src-pages-servicios-index-js" */)
}

